@import '../../../../scss/theme-bootstrap';

.social-gallery {
  display: none; // BEXPRGM-67 hiding temporarily for Pixlee migration
  padding: 35px 0;
  @include breakpoint($landscape-up) {
    padding: 55px 0;
  }
}
.social-gallery__header {
  text-align: center;
  padding: 0 20px;
  margin: 0 auto;
  @include breakpoint($landscape-up) {
    margin-bottom: 25px;
  }
}

.social-gallery__title {
  @include h2;
  margin: 0 0 7px 0;
  @include breakpoint($landscape-up) {
    margin-bottom: 15px;
  }
  > p {
    margin: 0;
    line-height: inherit;
  }
}

// Olapic Overrides
// !important declaration needed to override embedded styles.
.olapic {
  a {
    color: $color-off-black !important;
    text-decoration: none !important;
    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

.newcopyright-olapic,
.newcopyright-olapic-with-icon {
  display: none;
}

// Olapic Overlay
.olapic .viewer-previous a,
.olapic .viewer-next a {
  @include transition(opacity 0.2s ease);
  background: $color-off-black !important;
  color: $white !important;
  border-radius: 0 !important;
  opacity: 0.6;
  &:hover {
    background: inherit;
    opacity: 1;
  }
}

.olapic .viewer-close {
  width: 37px !important;
  height: 37px !important;
  font-size: 40px !important;
  line-height: 37px !important;
  @include breakpoint($landscape-up) {
    width: 55px !important;
    height: 50px !important;
    font-size: 60px !important;
    line-height: 50px !important;
  }
}

.olapic-viewer-overlay {
  padding: 20px;
  background: $color-overlay--dark !important;
  @include breakpoint($landscape-up) {
    @media screen and (max-height: 768px) {
      padding: 40px 20px;
    }
  }
}

.olapic-viewer-overlay #viewer-wrapper {
  top: 0 !important;
  @include breakpoint($landscape-up) {
    @include transform(translateY(-50%));
    top: 50% !important;
    position: absolute;
    width: 100%;
    @media screen and (max-height: 768px) {
      @include transform(translateY(0));
      top: 0 !important;
      position: static;
      width: auto;
    }
  }
}

.olapic-viewer-overlay .main-container {
  @include breakpoint($landscape-up) {
    height: 100%;
    @media screen and (max-height: 768px) {
      height: auto;
    }
  }
}

.olapic .navigation .olapic-wall-type-video i,
.olapic-type-video i,
.olapic .viewer-video-controls {
  background: none !important;
  margin: -42px 0 0 -40px !important;
  font-size: 68px;
  color: $white;
}

.olapic-item-media {
  .olapic-wall-type-video i {
    z-index: 1;
  }
}

.olapic .navigation {
  z-index: 20 !important;
}

.olapic .featured-photo {
  min-height: auto !important;
}

.olapic h1,
.olapic h2,
.olapic h3,
.olapic h4,
.olapic h5,
.olapic h6,
.olapic .h1,
.olapic .h2,
.olapic .h3,
.olapic .h4,
.olapic .h5,
.olapic .h6 {
  font-weight: normal !important;
  text-transform: uppercase !important;
  font-size: 22px;
  line-height: 22px;
}

.olapic h3 {
  padding: 30px 20px 10px 20px !important;
}

.olapic .sharing ul li:first-child {
  font-size: 13px !important;
  text-transform: uppercase;
}

.olapic .product-list-item-title {
  line-height: 15px;
}

.social-gallery__content {
  // Wall Widget
  .olapic-wall-widget {
    max-width: 304px;
    margin: 5px auto;
    @include breakpoint($portrait-up) {
      max-width: 768px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    @include breakpoint($landscape-up) {
      max-width: 1016px;
    }
  }
  .olapic-wall-list-container {
    padding: 0;
  }
  .olapic-wall.grid {
    padding: 0;
  }
  .olapic-wall.grid.mode_packery li.item {
    margin: 6px !important;
  }
  .olapic-wall.grid li.item .olapic-item-info {
    border: 5px solid $white;
    border: 0;
  }
  .olapic-wall.grid.mode_packery li.item,
  .olapic-wall.grid.mode_packery li.item:nth-child(4n + 4) {
    width: 140px;
    height: 140px;
    @include breakpoint($portrait-up) {
      width: 230px;
      height: 230px;
    }
  }
  .olapic-wall.grid.mode_packery li.item:first-child,
  .olapic-wall.grid.mode_packery li.item:nth-child(8n) {
    width: 140px;
    height: 140px;
    @include breakpoint($portrait-up) {
      width: 230px;
      height: 230px;
    }
  }
  .olapic-wall-footer {
    min-height: 0;
  }
  .olapic-loadmore {
    @include font-smoothing;
    position: static;
    z-index: 90;
    margin: 15px auto 0 auto;
    font-family: $primary-font;
    // https://elcjira.atlassian.net/browse/ANR-1148
    // copy style from %button but without the display property so olapic lazy loading of button works
    padding: 9px 19px 8px 19px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    line-height: 16px;
    background: $white;
    color: $color-off-black;
    border: 1px solid $color-off-black;
    &:hover,
    &:active {
      cursor: pointer;
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
    &:active {
      color: $color-gray;
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      margin-top: 25px;
      width: 165px;
    }
  }
  .olapic .olapic-footer-buttons {
    display: none;
  }
  .olapic .olapic-wall-header .olapic-top .olapic-tools {
    display: none;
  }
  .olapic .olapic-wall-footer .newcopyright-olapic {
    padding: 0 10px;
    display: none;
  }
  .newcopyright-olapic p a:link::after,
  .newcopyright-olapic p a:visited::after {
    display: none;
  }
}
